.App {
  text-align: center;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Dosis", sans-serif;
}
p {
  font-family: "Nunito", sans-serif;
}
a{
  text-decoration:none;
  color:white;
}
.title a:hover{
color:white;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color:#352635;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color:#61dafb;
}

.search-container {
  width: 50%;
  height: auto;
  padding-bottom: 15px;
}
input[type="text"] {
  font-family: "Nunito", sans-serif;
  background-color:#eee0e0;
  border: 1px solid#08070c;
  box-sizing: border-box;
  border-radius: 100px;
  padding: 12px 20px 12px 40px;
  font-size: 16px;
  width: 100%;
}
input[type="text"]:focus {
  box-shadow: 2px 2px black;
  background-color:#e0d3d3;
  outline: none;
}
.nav{
  margin-bottom:20px;
}
.category {
  font-family: "Nunito", sans-serif;
  background-color:#604760;
  border-radius: 30px;
  margin-right: 10px;
  color:#eee0e0;
  text-decoration: none;
  padding: 7px;
}
.nav-link{
  display: block;
 
}
.category:hover {
  background-color:#eee0e0;
  color:#604760;
}
.modal-content{
  background-color:#352635;
}
.modal-header{
  border:none;
}

.btn-close{
filter: invert(1) hue-rotate(180deg);
}
.active{
  background-color:#eee0e0;
  color:#604760;
}
img{
  border-radius:36%;
}
.btn{
  background-color:#604760;
  color:#eee0e0;
}
.btn:hover{
  background-color:#eee0e0;
  color:#604760;
}

